<template>
  <!-- begin:: Footer -->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-between"
        v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2">2022©</span>
        Made by
        <a
            href="#"
            target="_blank"
            class="text-dark-75 text-hover-primary"
        >{{ customConfig.footer.copyright }}</a>
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2">
        <a
            :href="customConfig.footer.child.home"
            target="_blank"
            class="nav-link px-3"
        >Home Site</a
        >
        <a
            :href="customConfig.footer.child.privacy"
            target="_blank"
            class="nav-link pr-3 pl-0"
        >Privacy</a
        >
      </div>
      <!--end::Nav-->
    </div>
    <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-between"
        v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2">{{ customConfig.footer.owner }}</span>
        {{ customConfig.footer.text }}
      </div>
    </div>
    <!--end::Container-->
    <cookie-law theme="dark-lime"
                buttonText="Accetto">
      <div slot="message">
        Questo sito si serve di cookie per gestire autenticazione, navigazione e altre funzioni. Servendoti del nostro
        sito acconsenti al collocamento di questo tipo di cookie sul tuo dispositivo.
        <a :href="customConfig.footer.child.privacy" target="_blank">Visualizza la ns. Informativa Estesa.</a>
      </div>
    </cookie-law>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import {mapGetters} from "vuex";
import CookieLaw from 'vue-cookie-law'

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig", "customConfig"]),
    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  },
  components: {CookieLaw}
};
</script>
